import React from 'react';

export default ({ name, content, howItWorkData }) => (
    <React.Fragment>
        <h2 className="section-title">{howItWorkData}</h2>
        <hr />
        <br />
        <br />
        <div className="text-center">
            <h3>{name}</h3>
            <h5>{content}</h5>
        </div>
    </React.Fragment>
)