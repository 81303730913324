import React from "react"

export default ({ contents }) => (
  <section className="container row home-description small-content">
    {contents.map(({ icon, name, content }, count) => (
      <div className="col-12 col-md-3 col-sm-6 text-center" key={count}>
        <i className={icon}></i>
        <h4 className="font-myan">{name}</h4>
        <div className="font-myan">{content}</div>
      </div>
    ))}
  </section>
)
