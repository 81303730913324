import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ hanldePopUp, downloadApp }) => {
  const data = useStaticQuery(graphql`
    query {
      playstore: file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      appgallery: file(relativePath: { eq: "app_gallery.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      appstore: file(relativePath: { eq: "app_store.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <div className="col-12 text-center">
        <br />
        <br />
        <a href="https://play.google.com/store/apps/details?id=mm.com.motherfinance.borrower">
          <Img
            fixed={data.playstore.childImageSharp.fixed}
            style={{ verticalAlign: "middle" }}
          />
        </a>&nbsp;&nbsp;&nbsp;
        <a href="https://appgallery.cloud.huawei.com/ag/n/app/C102108591">
          <Img
            fixed={data.appgallery.childImageSharp.fixed}
            style={{ verticalAlign: "middle" }}
          />
        </a>&nbsp;&nbsp;&nbsp;
        <a href="https://apps.apple.com/sg/app/mother-finance/id1427614551">
          <Img
            fixed={data.appstore.childImageSharp.fixed}
            style={{ verticalAlign: "middle" }}
          />
        </a>
        <br />
        <Link
          to="/download-latest-apk"
          style={{ margin: 60, display: `inline-block` }}
        >
          {downloadApp}
        </Link>
      </div>
    </React.Fragment>
  )
}
