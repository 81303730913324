import React from 'react';
import HowItWorkTwo from './HowItWorkTwo';
import HowItWorkOne from './HowItWorkOne'

export default ({ howItWorkOne, howItWorkTwo, howItWorkData }) => (
    <React.Fragment>
        <section id="how-it-work" className="how-it-work small-content container">
            <HowItWorkOne {...howItWorkOne[0]} howItWorkData={howItWorkData} />
            <div className="container-fluid row">
                <HowItWorkTwo HowItWorkTwo={howItWorkTwo}></HowItWorkTwo>
            </div>
        </section>
    </React.Fragment>
)