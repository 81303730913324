import React from 'react';

export default ({ HowItWorkTwo }) => (
    <React.Fragment>
        {
            HowItWorkTwo.map(({ icon, name, content }, count) => (
                <div className="col-md-4 col-12 text-center" key={count}>
                    <i className={icon}></i>
                    <h4>{name}</h4>
                    <div>
                        {content}
                    </div>
                </div>
            ))
        }
    </React.Fragment>
)

