import React from "react"

export default ({ data, index, handleGetStartedStep }) => (
  <React.Fragment>
    <div className="wrapper" data-step={data}>
      <div
        className={`${index === data && "animate"}  number`}
        onClick={() => handleGetStartedStep(data)}
        onKeyDown={() => handleGetStartedStep(data)}
        role="button"
        tabIndex={0}
      >
        {data + 1}
      </div>
      <div className="vertical-line aim-2"></div>
    </div>
  </React.Fragment>
)
