import React, { useState } from "react"
import { Modal } from "react-bootstrap"

export default () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  return (
    <>
      <div className="col-12 text-center">
        <button className="user-guide" onClick={() => setShow(!show)}>
          User Guide
        </button>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>User Guide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="User Guide"
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/videoseries?list=PL9F03rvypYLFVQ5d8Q_cJ-wBtG_3Au-gA"
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
