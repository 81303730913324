import React, { useEffect } from "react"
import Swiper from "swiper"
import "swiper/css/swiper.css"

export default ({ reviewPosts, reviewData }) => {
  useEffect(() => {
    new Swiper(".swiper-container", {
      loop: true,
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      slideNext: true,
      cssMode: false,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    })
  }, [])

  return (
    <React.Fragment>
      <section className="small-content container">
        <h2 className="section-title">{reviewData.title}</h2>
        <hr />
      </section>
      <br />
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {reviewPosts.map((data, key) => (
            <div className="swiper-slide" key={key}>
              <div dangerouslySetInnerHTML={{ __html: data }} />
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </React.Fragment>
  )
}
