import React, { useEffect } from "react"
import Swiper from "swiper"
import "swiper/css/swiper.css"
import SwiperSlide from "./SwiperSlide"
import { Link } from "gatsby"

export default ({ helpCenterData, helpCenter }) => {
  useEffect(() => {
    new Swiper(".swiper-container", {
      loop: true,
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      slideNext: true,
      cssMode: false,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    })
  }, [])

  return (
    <React.Fragment>
      <section className="small-content container">
        <h2 className="section-title">{helpCenterData[0]}</h2>
        <hr />
      </section>
      <br />
      <h3 className="text-center">{helpCenterData[1]}</h3>
      <br />
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {helpCenter.map((data, count) => (
            <SwiperSlide key={count} {...data}></SwiperSlide>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
      <br />
      <div className="text-center fake-link">
        {helpCenterData[2]}
        <Link to={helpCenterData[3]}> {helpCenterData[4]}</Link>
      </div>
    </React.Fragment>
  )
}
