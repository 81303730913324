import React, { useState } from "react"
import StepTitle from "./StepTitle"
import AppScreenShot from "./AppScreenShot"
import AppDownload from "./AppDownload"
import StartedStep from "./StartedStep"
import UserGuideVideo from "./UserGuideVideo"

export default ({ getStart, downloadApp, stepTitles, images }) => {
  const [sendToSide, setSendToSide] = useState(false)
  const [index, setIndex] = useState(null)

  const handleGetStartedStep = index => {
    if (!sendToSide) {
      setSendToSide(true)
    }
    showHideImage(index)
  }

  const showHideImage = index => {
    setIndex(index)
  }

  return (
    <>
      <section id="getstart" className="small-content container">
        <h2 className="section-title">{getStart}</h2>
        <hr />
        <div className="container-fluid row">
          <div
            className={`${
              sendToSide ? "col-6" : "col-12"
            } d-none d-sm-none d-md-block`}
            id="steps-tracker-holder"
          >
            <div className="steps-tracker">
              {[...Array(5).keys()].map(data => (
                <StartedStep
                  key={data}
                  data={data}
                  handleGetStartedStep={handleGetStartedStep}
                  index={index}
                />
              ))}
            </div>
            <StepTitle
              handleGetStartedStep={handleGetStartedStep}
              index={index}
              stepTitles={stepTitles}
            />
          </div>
          <div className="col-6 screenshots d-none d-sm-none d-md-block">
            {images.map((image, count) => (
              <AppScreenShot
                key={count}
                count={count}
                index={index}
                image={image}
              />
            ))}
          </div>
          <AppDownload downloadApp={downloadApp} />
          <UserGuideVideo />
        </div>
      </section>
    </>
  )
}
