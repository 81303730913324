import React from "react"
export default ({ handleGetStartedStep, index, stepTitles }) => (
  <React.Fragment>
    <div className="steps-title">
      {stepTitles.map(({ title }, stepTitleIndex) => {
        return (
          <div
            id={`step-${stepTitleIndex}-title`}
            className={`wrapper content-wrapper ${
              index === stepTitleIndex ? "highlight" : ""
            }`}
            data-step={stepTitleIndex}
            key={stepTitleIndex}
          >
            <div
              onClick={() => handleGetStartedStep(stepTitleIndex)}
              onKeyDown={() => handleGetStartedStep(stepTitleIndex)}
              role="button"
              tabIndex={0}
            >
              <h3 className="anim-1">{title}</h3>
            </div>
          </div>
        )
      })}
    </div>
  </React.Fragment>
)
