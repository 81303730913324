import React from "react"

export default ({ abouts }) => (
  <React.Fragment>
    {abouts.map((about, index) => (
      <section id="about" className="small-content container" key={index}>
        <h2 className="section-title">{about.name}</h2>
        <hr />
        <br />
        <p>{about.content}</p>
      </section>
    ))}
  </React.Fragment>
)
