import React from "react"
import { FacebookProvider, Page } from "react-facebook"

export default ({ contactUsData, contactNumbers }) => (
  <React.Fragment>
    <section id="contact-us" className="small-content container">
      <h2 className="section-title">{contactUsData[0]}</h2>
      <hr />
      <div className="row">
        <div className="col-md-5 col-12">
          <h3 className="section-sub-title">{contactUsData[1]}</h3>
          <div className="message-us">
            <a href="mailto:bd@motherfinance.com.mm" className="item">
              <i className="far fa-envelope"></i> bd@motherfinance.com.mm
            </a>
            <div className="item">
              <i className="fab fa-whatsapp" style={{ color: `#4eca5d` }}></i>
              <i className="fab fa-viber" style={{ color: `#834995` }}>
                {" "}
              </i>{" "}
              +95 978 741 0025
            </div>
            <a href="https://m.me/motherfinancemyanmar" className="item">
              <i className="fab fa-facebook-messenger"></i>{" "}
              facebook/motherfinancemyanmar
            </a>
          </div>
          <div className="social">
            <a
              href="https://www.facebook.com/motherfinancemyanmar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook" aria-label="Facebook"></i>
            </a>
            <a
              href="https://twitter.com/FinanceMother/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter" aria-label="Twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/mother_finance/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" aria-label="Instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/mother-finance"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin" aria-label="Linkedin"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCJRchp5Ru8eV8MmksItYJIw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube" aria-label="You Tube"></i>
            </a>
          </div>
          <br />
          <FacebookProvider appId="129003334503323">
            <Page
              href="https://www.facebook.com/motherfinancemyanmar/"
              tabs="Overview"
            />
          </FacebookProvider>
          <br />
        </div>
        <div className="col-md-4 col-12">
          <h3 className="section-sub-title">{contactUsData[2]}</h3>
          <div className="message-us">
            <div className="top-align-item">
              <i className="fas fa-phone"></i>
              <div>
                {contactNumbers.phone.map((num, key) => (
                  <React.Fragment key={key}>
                    <a href={"tel:" + num}>{num}</a>
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <span className="item">
              <i className="fas fa-fax"></i>
              {contactNumbers.fax}
            </span>
          </div>
          <br />
        </div>
        <div className="col-md-3 col-12">
          <h3 className="section-sub-title">{contactUsData[3]}</h3>
          <div className="message-us">
            <div className="top-align-item">
              <i className="fas fa-map-marker"></i>
              <div>
                Mother Finance Co., Ltd
                <br />
                No. 44/46, Street 2, Ward 1<br />
                Lanmadaw Township
                <br />
                Yangon, Myanmar 11131
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
)
