import React from 'react';

export default ({ name, content, icon }) => (
    <React.Fragment>
        <div className="swiper-slide">
            <i className={icon}></i>
            <h4>{name}</h4>
            <div>
                {content}
            </div>
        </div>
    </React.Fragment>
)